<template>
<ViewLayout className="success">
	<template v-slot:body>
		<h1>{{ $store.strings.sorry_successTitle }}</h1>
		<p v-if="$store.strings.sorry_successText">{{ $store.strings.sorry_successText }}</p>
	</template>
	<template v-slot:footer>
			<router-link to="/">
				<button class="uk-button uk-button-primary uk-button-large blue-action-button">
					{{ $store.strings.sorry_successReturn }}
				</button>
			</router-link>
			<router-link :to="'result/' + $store.requestID" class="go-back-action-button" onclick="_paq.push(['trackEvent', 'contact', 'back']);">
				{{ $store.strings.results_backToCeramics }}
			</router-link>
	</template>
</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue';

export default {
	name: 'Success',
	components: {
		ViewLayout,
	},
}
</script>