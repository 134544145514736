<template>
	<ViewLayout className="sorry">
		<template v-slot:body>
			<h1>{{ $store.strings.sorry_title }}</h1>
			<p v-if="$store.strings.sorry_intro">{{ $store.strings.sorry_intro }}</p>
			<div>
				<form class="uk-grid-small" uk-grid ref="form">
					<div class="uk-width-1-1">
						<input class="uk-input" type="text" :placeholder="$store.strings.sorry_fieldName" required v-model="name" />
					</div>
					<div class="uk-width-1-1">
						<input class="uk-input" type="email" :placeholder="$store.strings.sorry_fieldEmail" required v-model="email" />
					</div>
					<div class="uk-width-1-1">
						<div class="checkbox-group">
							<input type="checkbox" required />
							<div class="checkbox-group__label" v-html="acceptPrivacy"></div>
						</div>
					</div>
				</form>
			</div>
		</template>
		<template v-slot:footer>
			<div class="uk-button-group">
				<button class="uk-button uk-button-primary uk-button-large blue-action-button" @click="submit()" :disabled="sending" onclick="_paq.push(['trackEvent', 'contact', 'submit']);">
					<template v-if="sending">{{ $store.strings.sorry_sending }}</template>
					<template v-else><i uk-icon="icon: mail"></i>{{ $store.strings.sorry_send }}</template>
				</button>
			</div>
			<router-link :to="'result/' + $store.requestID" class="go-back-action-button" onclick="_paq.push(['trackEvent', 'contact', 'back']);">
				{{ $store.strings.general_back }}
			</router-link>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import Vue from 'vue'

export default {
	name: 'Sorry',
	data: function() {
		return {
			name: '',
			email: '',
			sending: false,
		}
	},
	computed: {
		acceptPrivacy() {
			return this.$store.strings.sorry_privacyAccept.replace(
				'{link}',
				'<a href="' + this.$store.strings.general_privacyPolicyURL.url + '" target="_blank">'+this.$store.strings.general_privacyPolicy+'</a>'
			)
		},
	},
	components: {
		ViewLayout,
	},
	methods: {
		submit() {
			if (!this.$refs.form.reportValidity())
				return

			this.sending = true

			const data = {
				mail: this.email,
				reqId: this.$store.requestID,
				name: this.name,
			}

			if (
				process.env.VUE_APP_USE_DEMO_RESPONSE !== 'true'
			) {
				// TODO: instead let strapi handle this
				fetch('https://mailer.wcsitzfinder.duravit.visionsbox.de/', {
					method: 'POST', // or 'PUT'
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				})
				.then(data => {
					console.log(data);
					if (!data.ok) {
						throw Error(data.statusText)
					}
					return data
				})
				.then(data => {
					console.log('Success:', data)
					this.sending = false

					// Vue.$toast.open({
					//   message: `<strong>Vielen Dank für Ihre Nachricht!</strong><p class="uk-text-small">Unsere Experten sichten Ihre Fotos und werden sich baldmöglichst bei Ihnen melden.</p>`,
					//   type: 'success',
					//   duration: 8000
					// })

					this.$router.push('/success')
				})
				.catch(error => {
					console.error('Error:', error);
					this.sending = false;
					const errorMessageTemplate = this.$store.strings.sorry_errorMessage.replace(
						'{email}',
						'<a href="mailto:' + this.$store.strings.general_email + '">' + this.$store.strings.general_email + '</a>'
					).replace(
						'{phone}',
						'<a href="tel' + this.$store.strings.general_phone + '">' + this.$store.strings.general_phone + '</a>'
					).split('\n')
					let errorMessage = ''
					if (errorMessageTemplate.length) {
						for (let e=0; e<errorMessageTemplate.length; ++e) {
							if (e==0)
								errorMessage += `<strong>${errorMessageTemplate[e]}</strong>`
							else
								errorMessage += `<p class="uk-text-small">${errorMessageTemplate[e]}</p>`
						}
					}

					Vue.$toast.open({
						message: errorMessage,
						type: 'error',
						duration: 8000
					});
				})
			} else {
					this.sending = false
					this.$router.push('/success')
			}
		}
	},
	created: function() {
		if (!this.$store.requestID) {
			this.$router.push('/')
			return
		}
	},
}

// this.$store.image
</script>
<style lang="scss" scoped>
.uk-button-group {
	.uk-button {
		min-width: 100px;
		text-align: center;
	}
}

.checkbox-group {
	position: relative;

	padding-left: 30px;

	input[type="checkbox"] {
		position: absolute;
		left: 0;
	}
}

::placeholder {
	text-transform: uppercase;
}

.checkbox-group__label {
}
</style>
